import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Events() {
  return (
    <>
      <Header></Header>
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>Event</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Event
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {/* Article Start */}
                <article className="sigma_post sigma_post-list event-list">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/events/1.jpg" alt="post" />
                    </a>
                    <div className="event-date-wrapper">
                      <span>20</span>
                      Aug'21
                    </div>
                  </div>
                  <div className="sigma_post-body">
                    <h5>
                      <a href="#">
                        People ask questions related to Hinduism
                      </a>
                    </h5>
                    <p>
                      You should be able to find several indispensable facts
                      about motivation in the following paragraphs. If there’s
                      at least one fact you didn’t know before, imagine the
                      difference it might make. It’s so difficult to go on when
                      everything seems to fail, isn’t it.
                    </p>
                    <div className="sigma_post-meta">
                      <a href="#">
                        <i className="far fa-clock" /> Sunday (8:00 am -9:00 am)
                      </a>
                      <a href="#">
                        <i className="far fa-map-marker-alt" /> 56 Thatcher
                        Avenue River Forest
                      </a>
                    </div>
                    <div className="section-button d-flex align-items-center">
                      <a href="#" className="sigma_btn-custom">
                        Join Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </article>
                {/* Article End */}
                {/* Article Start */}
                <article className="sigma_post sigma_post-list event-list">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/events/2.jpg" alt="post" />
                    </a>
                    <div className="event-date-wrapper">
                      <span>16</span>
                      Sep'21
                    </div>
                  </div>
                  <div className="sigma_post-body">
                    <h5>
                      <a href="#">
                        Temple is a place where Hindu worship
                      </a>
                    </h5>
                    <p>
                      You should be able to find several indispensable facts
                      about motivation in the following paragraphs. If there’s
                      at least one fact you didn’t know before, imagine the
                      difference it might make. It’s so difficult to go on when
                      everything seems to fail, isn’t it.
                    </p>
                    <div className="sigma_post-meta">
                      <a href="#">
                        <i className="far fa-clock" /> Sunday (8:00 am -9:00 am)
                      </a>
                      <a href="#">
                        <i className="far fa-map-marker-alt" /> 56 Thatcher
                        Avenue River Forest
                      </a>
                    </div>
                    <div className="section-button d-flex align-items-center">
                      <a href="#" className="sigma_btn-custom">
                        Join Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </article>
                {/* Article End */}
                {/* Article Start */}
                <article className="sigma_post sigma_post-list event-list">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/events/3.jpg" alt="post" />
                    </a>
                    <div className="event-date-wrapper">
                      <span>20</span>
                      Aug'21
                    </div>
                  </div>
                  <div className="sigma_post-body">
                    <h5>
                      <a href="#">
                        Should I Believe in Rebirth?
                      </a>
                    </h5>
                    <p>
                      You should be able to find several indispensable facts
                      about motivation in the following paragraphs. If there’s
                      at least one fact you didn’t know before, imagine the
                      difference it might make. It’s so difficult to go on when
                      everything seems to fail, isn’t it.
                    </p>
                    <div className="sigma_post-meta">
                      <a href="#">
                        <i className="far fa-clock" /> Sunday (8:00 am -9:00 am)
                      </a>
                      <a href="#">
                        <i className="far fa-map-marker-alt" /> 56 Thatcher
                        Avenue River Forest
                      </a>
                    </div>
                    <div className="section-button d-flex align-items-center">
                      <a href="#" className="sigma_btn-custom">
                        Join Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </article>
                {/* Article End */}
                {/* Article Start */}
                <article className="sigma_post sigma_post-list event-list">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/events/4.jpg" alt="post" />
                    </a>
                    <div className="event-date-wrapper">
                      <span>20</span>
                      Apr'21
                    </div>
                  </div>
                  <div className="sigma_post-body">
                    <h5>
                      <a href="#">
                        Serving the needs of the Hindu Community is untram.
                      </a>
                    </h5>
                    <p>
                      You should be able to find several indispensable facts
                      about motivation in the following paragraphs. If there’s
                      at least one fact you didn’t know before, imagine the
                      difference it might make. It’s so difficult to go on when
                      everything seems to fail, isn’t it.
                    </p>
                    <div className="sigma_post-meta">
                      <a href="#">
                        <i className="far fa-clock" /> Sunday (8:00 am -9:00 am)
                      </a>
                      <a href="#">
                        <i className="far fa-map-marker-alt" /> 56 Thatcher
                        Avenue River Forest
                      </a>
                    </div>
                    <div className="section-button d-flex align-items-center">
                      <a href="#" className="sigma_btn-custom">
                        Join Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </article>
                {/* Article End */}
                {/* Article Start */}
                <article className="sigma_post sigma_post-list event-list">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/events/2.jpg" alt="post" />
                    </a>
                    <div className="event-date-wrapper">
                      <span>18</span>
                      June'21
                    </div>
                  </div>
                  <div className="sigma_post-body">
                    <h5>
                      <a href="#">
                        Some Hindu teachers insist that believing in rebirth
                      </a>
                    </h5>
                    <p>
                      You should be able to find several indispensable facts
                      about motivation in the following paragraphs. If there’s
                      at least one fact you didn’t know before, imagine the
                      difference it might make. It’s so difficult to go on when
                      everything seems to fail, isn’t it.
                    </p>
                    <div className="sigma_post-meta">
                      <a href="#">
                        <i className="far fa-clock" /> Sunday (8:00 am -9:00 am)
                      </a>
                      <a href="#">
                        <i className="far fa-map-marker-alt" /> 56 Thatcher
                        Avenue River Forest
                      </a>
                    </div>
                    <div className="section-button d-flex align-items-center">
                      <a href="#" className="sigma_btn-custom">
                        Join Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </article>
                {/* Article End */}
                {/* Pagination Start */}
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="far fa-chevron-left" />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      2 <span className="sr-only">(current)</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="far fa-chevron-right" />
                    </a>
                  </li>
                </ul>
                {/* Pagination End */}
              </div>
              <div className="col-lg-4">
                <div className="sidebar">
                  {/* About Author Start */}
                  <div className="sidebar-widget widget-about-author">
                    <h5 className="widget-title">About Me</h5>
                    <div className="widget-about-author-inner">
                      <img src="assets/img/blog/author.jpg" alt="author" />
                      <h5>Rosalina D. Willaimson</h5>
                      <p>
                        "Praesent sapien massa, convallis a pellentesque nec,
                        egestas non nisi. Donec sollicitudin molestie malesuada.
                        Mauris blandit aliquet elit"
                      </p>
                      <ul className="sigma_sm">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-youtube" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* About Author End */}
                  {/* Search Widget Start */}
                  <div className="sidebar-widget widget-search">
                    <h5 className="widget-title">Search</h5>
                    <form method="post">
                      <div className="sigma_search-adv-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Posts"
                          name="search"
                          defaultValue
                        />
                        <button type="submit" name="button">
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* Search Widget End */}
                  {/* Popular Feed Start */}
                  <div className="sidebar-widget widget-recent-posts">
                    <h5 className="widget-title">Recent Posts</h5>
                    <article className="sigma_recent-post">
                      <a href="#">
                        <img src="assets/img/blog/md/1.jpg" alt="post" />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          <a href="#">
                            As we've all discovered by now, the world can change
                          </a>
                        </h6>
                        <a href="#">
                          <i className="far fa-calendar" /> May 20, 2024
                        </a>
                      </div>
                    </article>
                    <article className="sigma_recent-post">
                      <a href="#">
                        <img src="assets/img/blog/md/2.jpg" alt="post" />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          <a href="#">
                            Testimony love offering so blessed
                          </a>
                        </h6>
                        <a href="#">
                          <i className="far fa-calendar" /> May 20, 2024
                        </a>
                      </div>
                    </article>
                    <article className="sigma_recent-post">
                      <a href="#">
                        <img src="assets/img/blog/md/3.jpg" alt="post" />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          <a href="#">
                            As we've all discovered by now, the world can change
                          </a>
                        </h6>
                        <a href="#">
                          <i className="far fa-calendar" /> May 20, 2024
                        </a>
                      </div>
                    </article>
                  </div>
                  {/* Popular Feed End */}
                  {/* Categories Start */}
                  <div className="sidebar-widget widget-categories">
                    <h5 className="widget-title">Our Categories</h5>
                    <ul className="sidebar-widget-list">
                      <li>
                        <a href="#">
                          Avatars <span>32</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Festivals <span>22</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Mahabharat <span>17</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Mythology <span>05</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Religion <span>32</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Uncategorized <span>12</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Ramayan <span>42</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Bhagwat Gita <span>04</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Categories End */}
                  {/* Social Media Start */}
                  <div className="sidebar-widget">
                    <h5 className="widget-title">Never Miss Out</h5>
                    <ul className="sigma_sm square light">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Social Media End */}
                  {/* Twitter Feed Start */}
                  <div className="sidebar-widget widget-twitter">
                    <h5 className="widget-title">Twitter Feed</h5>
                    <div className="twitter-item d-flex align-items-start">
                      <i className="fab fa-twitter custom-primary me-4 mt-2" />
                      <div className="twitter-item-content">
                        <p>
                          Rescue - #Gutenberg ready 
                        </p>
                        <strong>November 25, 2024</strong>
                      </div>
                    </div>
                    <div className="twitter-item d-flex align-items-start">
                      <i className="fab fa-twitter custom-primary me-4 mt-2" />
                      <div className="twitter-item-content">
                        <p>
                          Rescue - #Gutenberg ready 
                        </p>
                        <strong>November 25, 2024</strong>
                      </div>
                    </div>
                    <div className="twitter-item d-flex align-items-start">
                      <i className="fab fa-twitter custom-primary me-4 mt-2" />
                      <div className="twitter-item-content">
                        <p>
                          Rescue - #Gutenberg ready 
                        </p>
                        <strong>November 25, 2024</strong>
                      </div>
                    </div>
                  </div>
                  {/* Twitter Feed End */}
                  {/* Instagram Start */}
                  <div className="sidebar-widget widget-ig">
                    <h5 className="widget-title">Instagram</h5>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/1.jpg" alt="ig" />
                        </a>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/2.jpg" alt="ig" />
                        </a>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/3.jpg" alt="ig" />
                        </a>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/4.jpg" alt="ig" />
                        </a>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/5.jpg" alt="ig" />
                        </a>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/6.jpg" alt="ig" />
                        </a>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/7.jpg" alt="ig" />
                        </a>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/8.jpg" alt="ig" />
                        </a>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <a href="#" className="sigma_ig-item">
                          <img src="assets/img/ig/9.jpg" alt="ig" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Instagram End */}
                  {/* Popular Tags Start */}
                  <div className="sidebar-widget">
                    <h5 className="widget-title">Popular Tags</h5>
                    <div className="tagcloud">
                      <a href="#">Isabella</a>
                      <a href="#">Samantha</a>
                      <a href="#">Emily</a>
                      <a href="#">June</a>
                      <a href="#">Grace</a>
                      <a href="#">Madison</a>
                      <a href="#">Heather</a>
                      <a href="#">Micheal</a>
                    </div>
                  </div>
                  {/* Popular Tags End */}
                  {/* Ad banner Start */}
                  <div className="sidebar-widget widget-ad p-0 border-0">
                    <a href="#">
                      <img src="assets/img/blog/ad.jpg" alt="ad" />
                      <div>
                        <span>Add a Custom Text</span>
                        Add Banner here
                      </div>
                    </a>
                  </div>
                  {/* Ad banner End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
}

export default Events;
