import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

function Puja() {
  const [activeFilter, setActiveFilter] = useState("*");

  // Data for portfolio items
  const pujaItems = [
    {
      category: "coaching",
      imgSrc: "assets/img/puja/1.jpg",
      title: "Durga Puja",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "strategy",
      imgSrc: "assets/img/puja/2.jpg",
      title: "Raksha Bandhan",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "coaching strategy",
      imgSrc: "assets/img/puja/3.jpg",
      title: "Janmashtmi",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "relations coaching",
      imgSrc: "assets/img/puja/4.jpg",
      title: "Mahashivratri",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "stakeholder strategy",
      imgSrc: "assets/img/puja/5.jpg",
      title: "Holi",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "stakeholder",
      imgSrc: "assets/img/puja/6.jpg",
      title: "Diwali",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
  ];

  // Function to handle tab click
  const handleTabClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <>
      <Header />
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>Puja</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Puja
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        {/* Categories Start */}
        <div className="section section-padding">
          <div className="container">
            {/* Section Title */}
            <div className="section-title text-start flex-title">
              <div>
                <p className="subtitle">Puja</p>
                <h4 className="title mb-lg-0">Our Puja</h4>
              </div>
              {/* Tabs */}
              <div className="text-center filter-items me-0 mb-0">
                <h5
                  className={`portfolio-trigger ${
                    activeFilter === "*" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("*")}
                >
                  All
                </h5>
                <h5
                  className={`portfolio-trigger ${
                    activeFilter === "coaching" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("coaching")}
                >
                  Durga Puja
                </h5>
                <h5
                  className={`portfolio-trigger ${
                    activeFilter === "strategy" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("strategy")}
                >
                  Raksha Bandhan
                </h5>
                <h5
                  className={`portfolio-trigger ${
                    activeFilter === "stakeholder" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("stakeholder")}
                >
                  Holi
                </h5>
              </div>
            </div>

            {/* Portfolio Items */}
            <div className="portfolio-filter row">
              {pujaItems
                .filter(
                  (item) =>
                    activeFilter === "*" || item.category.includes(activeFilter)
                )
                .map((item, index) => (
                  <div key={index} className={`col-lg-4 ${item.category}`}>
                    <div className="sigma_portfolio-item">
                      <img src={item.imgSrc} alt="portfolio" />
                      <div className="sigma_portfolio-item-content">
                        <div className="sigma_portfolio-item-content-inner">
                          <h5>
                            <a href="#">{item.title}</a>
                          </h5>
                          <p className="blockquote bg-transparent">
                            {item.description}
                          </p>
                        </div>
                        <a href="#">
                          <i className="fal fa-plus" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* Categories End */}
        {/* Form Start */}
        <div
          className="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat"
          style={{ backgroundImage: "url(assets/img/bg1.jpg)" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-lg-30">
                <form method="post">
                  <div className="form-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-user" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="First Name"
                          name="fname"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-user" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Last Name"
                          name="lname"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-pencil" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Subject"
                          name="subject"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-envelope" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Email Address"
                          name="email"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control transparent"
                          placeholder="Enter Message"
                          rows={4}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        className="sigma_btn-custom d-block w-100"
                        name="button"
                      >
                        Get a Quote <i className="far fa-arrow-right" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/1.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/2.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/3.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/4.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/5.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/6.png" alt="client" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Form End */}
        {/* Blog Start */}
        <div className="section section-padding">
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle">Blog</p>
              <h4 className="title">News Feed</h4>
            </div>
            <div className="row">
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/2.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a href="#" className="sigma_post-category">
                          Temple
                        </a>
                        ,
                        <a href="#" className="sigma_post-category">
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/1.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/1.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a href="#" className="sigma_post-category">
                          Temple
                        </a>
                        ,
                        <a href="#" className="sigma_post-category">
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/2.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/3.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a href="#" className="sigma_post-category">
                          Temple
                        </a>
                        ,
                        <a href="#" className="sigma_post-category">
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/1.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
            </div>
          </div>
        </div>
        {/* Blog End */}
      </>

      <Footer />
    </>
  );
}

export default Puja;
