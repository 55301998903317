import Home from "./Components/Home";
import Blog from "./Components/Blog";
import Shop from "./Components/Shop";
import Puja from "./Components/Puja";
import Faqs from "./Components/Faqs";
import Cart from "./Components/Cart";
import Live from "./Components/Live";
import Events from "./Components/Events";
import Contact from "./Components/Contact";
import AboutUs from "./Components/AboutUs";
import Service from "./Components/Service";
import BlogDetails from "./Components/Blog";
import CheckOut from "./Components/CheckOut";
import Wishlist from "./Components/Wishlist";
import Donation from "./Components/Donation";
import Volunteer from "./Components/Volunteer";
import { Routes, Route } from "react-router-dom";
import ShopDetails from "./Components/ShopDetails";
import ServiceDetails from "./Components/ServiceDetails";
import VolunteerDetail from "./Components/VolunteerDetail";
import DonationArchive from "./Components/DonationArchive";

function App() {
  return (
    <div>
      <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Shop" element={<Shop />} />
          <Route path="/Puja" element={<Puja />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Live" element={<Live />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Service" element={<Service />} />
          <Route path="/Donation" element={<Donation />} />
          <Route path="/Wishlist" element={<Wishlist />} />
          <Route path="/CheckOut" element={<CheckOut />} />
          <Route path="/Volunteer" element={<Volunteer />} />
          <Route path="/ShopDetails" element={<ShopDetails />} />
          <Route path="/BlogDetails" element={<BlogDetails />} />
          <Route path="/ServiceDetails" element={<ServiceDetails />} />
          <Route path="/DonationArchive" element={<DonationArchive />} />
          <Route path="/VolunteerDetail" element={<VolunteerDetail />} />
        </Routes>
      </>
    </div>
  );
}

export default App;
