import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function AboutUs() {
  return (
    <>
      <Header />
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>About Us</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a className="btn-link" href="#">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {/* About Start */}
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 d-none d-lg-block">
              <div className="sigma_img-box">
                <div className="row">
                  <div className="col-lg-6">
                    <img src="assets/img/service/details/5.jpg" alt="service" />
                    <img
                      className="ms-0"
                      src="assets/img/service/details/6.jpg"
                      alt="service"
                    />
                  </div>
                  <div className="col-lg-6 mt-0 mt-lg-5">
                    <img src="assets/img/service/details/7.jpg" alt="service" />
                    <img
                      className="ms-0"
                      src="assets/img/service/details/8.jpg"
                      alt="service"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="me-lg-30">
                <div className="section-title mb-0 text-start">
                  <p className="subtitle">EDUCATION FOR ALL RURAL CHILDREN</p>
                  <h4 className="title">We are a Hindu that believe in Ram</h4>
                </div>
                <p className="blockquote bg-transparent">
                  We are a Hindu that belives in Lord Rama and Vishnu Deva the
                  followers and We are a Hindu that belives in Lord Rama and
                  Vishnu Deva.
                </p>
                <div className="sigma_icon-block icon-block-3">
                  <div className="icon-wrapper">
                    <img src="assets/img/textures/icons/1.png" alt="" />
                  </div>
                  <div className="sigma_icon-block-content">
                    <h5>Temple</h5>
                    <p>
                      It was popularised in the 1960s with the release of
                      Letraset sheets containing Lorem Ipsum passages.
                    </p>
                  </div>
                </div>
                <div className="sigma_icon-block icon-block-3">
                  <div className="icon-wrapper">
                    <img src="assets/img/textures/icons/2.png" alt="" />
                  </div>
                  <div className="sigma_icon-block-content">
                    <h5>Puja</h5>
                    <p>
                      It was popularised in the 1960s with the release of
                      Letraset sheets containing Lorem Ipsum passages.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About End */}
      {/* History Start */}
      <div className="section light-bg">
        <div className="container">
          <div className="sigma_timeline">
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>Some Important Life Lessons From Bhagwat Gita</h4>
                <p>
                  Temple is a place where Hindu worship our Bhagwan Ram, Shiva,
                  Vishnu, Krishna etc. Proin eget tortor industry's standard
                  dummy text ever since the 1500s, when an unknown printer took
                  a galley of type, People ask questions related to Hinduism.
                  specimen book.
                </p>
              </div>
              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle" />
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span>2019</span>
                <br />
                <span className="sigma_timeline-month">JUN 1</span>
              </div>
            </div>
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>People ask questions related to Hinduism</h4>
                <p>
                  People ask questions related to Hinduism. Temple is a place
                  where Hindu worship our Bhagwan Ram, Shiva, Vishnu, Krishna
                  etc. Proin eget tortor industry's standard dummy text ever
                  since the 1500s, when an unknown
                </p>
              </div>
              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle" />
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span>2020</span>
                <br />
                <span className="sigma_timeline-month">JUN 1</span>
              </div>
            </div>
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>Some Important Life Lessons From Bhagwat Gita</h4>
                <p>
                  Temple is a place where Hindu worship our Bhagwan Ram, Shiva,
                  Vishnu, Krishna etc. Proin eget tortor industry's standard
                  dummy text ever since the 1500s, when an unknown printer took
                  a galley of type, People ask questions related to Hinduism.
                  specimen book.
                </p>
              </div>
              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle" />
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span>2024</span>
                <br />
                <span className="sigma_timeline-month">JUN 1</span>
              </div>
            </div>
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>People ask questions related to Hinduism</h4>
                <p>
                  People ask questions related to Hinduism. Temple is a place
                  where Hindu worship our Bhagwan Ram, Shiva, Vishnu, Krishna
                  etc. Proin eget tortor industry's standard dummy text ever
                  since the 1500s, when an unknown
                </p>
              </div>
              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle" />
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span>2024</span>
                <br />
                <span className="sigma_timeline-month">JUN 1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* History End */}
      {/* About Start */}
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="me-lg-30">
                <div className="section-title mb-0 text-start">
                  <p className="subtitle">Our Approach</p>
                  <h4 className="title">
                    Our mission is to share the Good of Hinduism
                  </h4>
                </div>
                <p className="blockquote bg-transparent">
                  We are a Hindu that belives in Lord Rama and Vishnu Deva the
                  followers and We are a Hindu that belives in Lord Rama and
                  Vishnu Deva.
                </p>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      <i className="fas fa-om" /> Holi
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      <i className="far fa-church" /> Temple
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      href="#contact"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      <i className="far fa-user" /> Pandit
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <p>
                      Our mission is to share the Good of Hinduism, Loving,
                      Faith and Serving. People ask questions related to
                      Hinduism. Temple is a place where Hindu worship our
                      Bhagwan Ram, Shiva, Vishnu, Krishna etc. Proin eget tortor
                      industry's standard dummy text ever
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <p>
                      Our mission is to share the Good of Hinduism, Loving,
                      Faith and Serving. People ask questions related to
                      Hinduism. Temple is a place where Hindu worship our
                      Bhagwan Ram, Shiva, Vishnu, Krishna etc. Proin eget tortor
                      industry's standard dummy text ever
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <p>
                      Our mission is to share the Good of Hinduism, Loving,
                      Faith and Serving. People ask questions related to
                      Hinduism. Temple is a place where Hindu worship our
                      Bhagwan Ram, Shiva, Vishnu, Krishna etc. Proin eget tortor
                      industry's standard dummy text ever
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/img/about.jpg" alt="about" />
            </div>
          </div>
        </div>
      </section>
      {/* About End */}
      {/* Form Start */}
      <div
        className="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat"
        style={{ backgroundImage: "url(assets/img/bg1.jpg)" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-lg-30">
              <form method="post">
                <div className="form-row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <i className="far fa-user" />
                      <input
                        type="text"
                        className="form-control transparent"
                        placeholder="First Name"
                        name="fname"
                        defaultValue
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <i className="far fa-user" />
                      <input
                        type="text"
                        className="form-control transparent"
                        placeholder="Last Name"
                        name="lname"
                        defaultValue
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <i className="far fa-pencil" />
                      <input
                        type="text"
                        className="form-control transparent"
                        placeholder="Subject"
                        name="subject"
                        defaultValue
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <i className="far fa-envelope" />
                      <input
                        type="text"
                        className="form-control transparent"
                        placeholder="Email Address"
                        name="email"
                        defaultValue
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control transparent"
                        placeholder="Enter Message"
                        rows={4}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      className="sigma_btn-custom d-block w-100"
                      name="button"
                    >
                      Get a Quote <i className="far fa-arrow-right" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="sigma_client">
                    <img src="assets/img/clients/1.png" alt="client" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sigma_client">
                    <img src="assets/img/clients/2.png" alt="client" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sigma_client">
                    <img src="assets/img/clients/3.png" alt="client" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sigma_client">
                    <img src="assets/img/clients/4.png" alt="client" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sigma_client">
                    <img src="assets/img/clients/5.png" alt="client" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sigma_client">
                    <img src="assets/img/clients/6.png" alt="client" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Form End */}
      {/* Blog Start */}
      <div className="section section-padding">
        <div className="container">
          <div className="section-title text-center">
            <p className="subtitle">Blog</p>
            <h4 className="title">News Feed</h4>
          </div>
          <div className="row">
            {/* Article Start */}
            <div className="col-lg-4 col-md-6">
              <article className="sigma_post">
                <div className="sigma_post-thumb">
                  <a href="#">
                    <img src="assets/img/blog/2.jpg" alt="post" />
                  </a>
                </div>
                <div className="sigma_post-body">
                  <div className="sigma_post-meta">
                    <div className="me-3">
                      <i className="fas fa-om" />
                      <a
                        href="#"
                        className="sigma_post-category"
                      >
                        Temple
                      </a>
                      ,
                      <a
                        href="#"
                        className="sigma_post-category"
                      >
                        Love
                      </a>
                    </div>
                    <a href="#" className="sigma_post-date">
                      <i className="far fa-calendar" /> May 20, 2024
                    </a>
                  </div>
                  <h5>
                    <a href="#">
                      Education for all rural children are necessary.
                    </a>
                  </h5>
                  <div className="sigma_post-single-author">
                    <img src="assets/img/people/1.jpg" alt="author" />
                    <div className="sigma_post-single-author-content">
                      By
                      <p>Yesh Chopra</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            {/* Article End */}
            {/* Article Start */}
            <div className="col-lg-4 col-md-6">
              <article className="sigma_post">
                <div className="sigma_post-thumb">
                  <a href="#">
                    <img src="assets/img/blog/1.jpg" alt="post" />
                  </a>
                </div>
                <div className="sigma_post-body">
                  <div className="sigma_post-meta">
                    <div className="me-3">
                      <i className="fas fa-om" />
                      <a
                        href="#"
                        className="sigma_post-category"
                      >
                        Temple
                      </a>
                      ,
                      <a
                        href="#"
                        className="sigma_post-category"
                      >
                        Love
                      </a>
                    </div>
                    <a href="#" className="sigma_post-date">
                      <i className="far fa-calendar" /> May 20, 2024
                    </a>
                  </div>
                  <h5>
                    <a href="#">
                      Education for all rural children are necessary.
                    </a>
                  </h5>
                  <div className="sigma_post-single-author">
                    <img src="assets/img/people/2.jpg" alt="author" />
                    <div className="sigma_post-single-author-content">
                      By
                      <p>Yesh Chopra</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            {/* Article End */}
            {/* Article Start */}
            <div className="col-lg-4 col-md-6">
              <article className="sigma_post">
                <div className="sigma_post-thumb">
                  <a href="#">
                    <img src="assets/img/blog/3.jpg" alt="post" />
                  </a>
                </div>
                <div className="sigma_post-body">
                  <div className="sigma_post-meta">
                    <div className="me-3">
                      <i className="fas fa-om" />
                      <a
                        href="#"
                        className="sigma_post-category"
                      >
                        Temple
                      </a>
                      ,
                      <a
                        href="#"
                        className="sigma_post-category"
                      >
                        Love
                      </a>
                    </div>
                    <a href="#" className="sigma_post-date">
                      <i className="far fa-calendar" /> May 20, 2024
                    </a>
                  </div>
                  <h5>
                    <a href="#">
                      Education for all rural children are necessary.
                    </a>
                  </h5>
                  <div className="sigma_post-single-author">
                    <img src="assets/img/people/1.jpg" alt="author" />
                    <div className="sigma_post-single-author-content">
                      By
                      <p>Yesh Chopra</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            {/* Article End */}
          </div>
        </div>
      </div>
      {/* Blog End */}

      <Footer />
    </>
  );
}

export default AboutUs;
