import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Live() {
  return (
    <>
      <Header> </Header>
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>Broadcast</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Broadcast
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        {/* Broadcast Start */}
        <div className="section section-padding">
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle">Watch Video</p>
              <h4 className="title">Our Live Broadcast</h4>
            </div>
            <div className="row sigma_broadcast-video">
              <div className="col-12 mb-5">
                <div className="row g-0 align-items-center">
                  <div className="col-lg-6">
                    <div className="sigma_video-popup-wrap">
                      <img src="assets/img/video-gallery/01.png" alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=TKnufs85hXk"
                        className="sigma_video-popup popup-youtube"
                      >
                        <i className="fas fa-play" />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_box m-0">
                      <p className="custom-primary mb-0 fw-600 fs-16">
                        Aug 12, 2024
                      </p>
                      <h4 className="title">
                        Serving the needs of the Hindu Community
                      </h4>
                      <p className="m-0">
                        We are a Hindu that belives in Lord Rama and Vishnu Deva
                        the followers and We are a Hindu that belives in Lord
                        Rama and Vishnu Deva. This is where you should start
                        Temple is place where hindu worship consectetur
                        adipisicing elit, sed do Temple is place where hindu
                        worship consectetur adipisicing elit, sed do This is
                        where you should start Lorem ipsum dolor sit amet
                        consectetur
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="sigma_video-popup-wrap">
                  <img src="assets/img/video-gallery/1.png" alt="video" />
                  <a
                    href="https://www.youtube.com/watch?v=TKnufs85hXk"
                    className="sigma_video-popup popup-sm popup-youtube"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
                <h6 className="mb-0 mt-3">Varansi at Night</h6>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="sigma_video-popup-wrap">
                  <img src="assets/img/video-gallery/2.jpg" alt="video" />
                  <a
                    href="https://www.youtube.com/watch?v=TKnufs85hXk"
                    className="sigma_video-popup popup-sm popup-youtube"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
                <h6 className="mb-0 mt-3">Chaar Dhaam Yatra</h6>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="sigma_video-popup-wrap">
                  <img src="assets/img/video-gallery/3.jpg" alt="video" />
                  <a
                    href="https://www.youtube.com/watch?v=TKnufs85hXk"
                    className="sigma_video-popup popup-sm popup-youtube"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
                <h6 className="mb-0 mt-3">Mahashivratri Temple</h6>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="sigma_video-popup-wrap">
                  <img src="assets/img/video-gallery/4.jpg" alt="video" />
                  <a
                    href="https://www.youtube.com/watch?v=TKnufs85hXk"
                    className="sigma_video-popup popup-sm popup-youtube"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
                <h6 className="mb-0 mt-3">OM Mahashivratri</h6>
              </div>
            </div>
          </div>
        </div>
        {/* Broadcast End */}
        {/* Blog Start */}
        <div className="section section-padding pt-0">
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle">Blog</p>
              <h4 className="title">News Feed</h4>
            </div>
            <div className="row">
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/2.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Temple
                        </a>
                        ,
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/1.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/1.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Temple
                        </a>
                        ,
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/2.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/3.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Temple
                        </a>
                        ,
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/1.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
            </div>
          </div>
        </div>
        {/* Blog End */}
      </>
      <Footer></Footer>
    </>
  );
}

export default Live;
