import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Slider from "react-slick";

function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          fade: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          fade: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          fade: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };

  const [activeFilter, setActiveFilter] = useState("*");

  // Data for portfolio items
  const pujaItems = [
    {
      category: "coaching",
      imgSrc: "assets/img/puja/1.jpg",
      title: "Durga Puja",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "strategy",
      imgSrc: "assets/img/puja/2.jpg",
      title: "Raksha Bandhan",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "coaching strategy",
      imgSrc: "assets/img/puja/3.jpg",
      title: "Janmashtmi",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "relations coaching",
      imgSrc: "assets/img/puja/4.jpg",
      title: "Mahashivratri",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "stakeholder strategy",
      imgSrc: "assets/img/puja/5.jpg",
      title: "Holi",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
    {
      category: "stakeholder",
      imgSrc: "assets/img/puja/6.jpg",
      title: "Diwali",
      description:
        "Puja is the worship of the Lord, consectet ur adipisicing elit, sed do eiusmod",
    },
  ];

  // Function to handle tab click
  const handleTabClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <div>
      <div>
        <Header />
        {/* partial */}
        {/* Banner Start */}
        <div
          className="sigma_banner banner-1 bg-cover light-overlay bg-center bg-norepeat"
          style={{ backgroundImage: "url(assets/img/banner/9.jpg)" }}
        >
          <div className="sigma_banner-slider">
            {/* Banner Item Start */}
            <Slider {...settings}>
              <div className="sigma_banner-slider-inner">
                <div className="sigma_banner-text">
                  <div className="container position-relative">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="sigma_box primary-bg banner-cta">
                          <h1 className="text-white title">
                            Mere Ganpati Guru Ganesh Ji Tusi Aa Jao.
                          </h1>
                          <p className="blockquote light light-border mb-0">
                            We are a Hindu that belives in Lord Rama and Vishnu
                            Deva the followers and We are a Hindu that belives
                            in Lord Rama and Vishnu Deva.
                          </p>
                          <div className="section-button d-flex align-items-center">
                            <a
                              href="#"
                              className="sigma_btn-custom secondary"
                            >
                              Join Today <i className="far fa-arrow-right" />
                            </a>
                            <a
                              href="#"
                              className="ms-3 sigma_btn-custom light text-white"
                            >
                              Donate Us <i className="far fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="d-none d-lg-block"
                      src="assets/img/banner/png/4.png"
                      alt="png"
                    />
                  </div>
                </div>
              </div>
              {/* Banner Item End */}
              {/* Banner Item Start */}
              <div className="sigma_banner-slider-inner">
                <div className="sigma_banner-text">
                  <div className="container position-relative">
                    <div className="row align-items-center">
                      <div className="offset-lg-6 col-lg-6">
                        <div className="sigma_box primary-bg banner-cta">
                          <h1 className="text-white title">
                            Welcome to Religion of the Hinduism
                          </h1>
                          <p className="blockquote light light-border mb-0">
                            We are a Hindu that belives in Lord Rama and Vishnu
                            Deva the followers and We are a Hindu that belives
                            in Lord Rama and Vishnu Deva.
                          </p>
                          <div className="section-button d-flex align-items-center">
                            <a
                              href="#"
                              className="sigma_btn-custom secondary"
                            >
                              Join Today <i className="far fa-arrow-right" />
                            </a>
                            <a
                              href="#"
                              className="ms-3 sigma_btn-custom light text-white"
                            >
                              Donate Us <i className="far fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="left d-none d-lg-block"
                      src="assets/img/banner/png/5.png"
                      alt="png"
                    />
                  </div>
                </div>
              </div>
            </Slider>
            {/* Banner Item End */}
          </div>
        </div>
        {/* Banner End */}
        {/* holi Start */}
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <a
                  href="#"
                  className="sigma_service border text-center style-1 bg-white"
                >
                  <div className="sigma_service-thumb">
                    <i className="flaticon-temple" />
                    <span />
                    <span />
                  </div>
                  <div className="sigma_service-body">
                    <h5>Temple</h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-md-6">
                <a
                  href="#"
                  className="sigma_service border text-center style-1 primary-bg"
                >
                  <div className="sigma_service-thumb">
                    <i className="text-white flaticon-arti" />
                    <span />
                    <span />
                  </div>
                  <div className="sigma_service-body">
                    <h5 className="text-white">Puja</h5>
                    <p className="text-white">
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-md-6">
                <a
                  href="#"
                  className="sigma_service border text-center style-1 secondary-bg"
                >
                  <div className="sigma_service-thumb">
                    <i className="custom-primary flaticon-pooja" />
                    <span />
                    <span />
                  </div>
                  <div className="sigma_service-body">
                    <h5 className="text-white">Donation</h5>
                    <p className="text-white">
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* holi End */}
        {/* About Start */}
        <section className="section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 d-none d-lg-block">
                <div
                  className="sigma_counter bg-cover primary-overlay bg-norepeat bg-center"
                  style={{ backgroundImage: "url(assets/img/counter.jpg)" }}
                >
                  <h4>
                    <b className="counter" data-from={0} data-to={25}>
                      25
                    </b>
                    <span>+</span>
                  </h4>
                  <p>Join Temple</p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="me-lg-30">
                  <div className="section-title mb-0 text-start">
                    <p className="subtitle">Education for all rural children</p>
                    <h4 className="title">
                      We Are A Hindu That Believes In Rama.
                    </h4>
                  </div>
                  <p className="blockquote bg-transparent">
                    We are a Hindu that belives in Lord Rama and Vishnu Deva the
                    followers and We are a Hindu that belives in Lord Rama and
                    Vishnu Deva.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="sigma_icon-block icon-block-3">
                        <div className="icon-wrapper">
                          <i className="flaticon-temple" />
                        </div>
                        <div className="sigma_icon-block-content">
                          <h5>Temple</h5>
                          <p>
                            Donation is a good act amet quam vehicula elementum
                            sed.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sigma_icon-block icon-block-3">
                        <div className="icon-wrapper">
                          <i className="flaticon-powder-1" />
                        </div>
                        <div className="sigma_icon-block-content">
                          <h5>Donation</h5>
                          <p>
                            Donation is a good act amet quam vehicula elementum
                            sed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a href="#" className="sigma_btn-custom light">
                    Learn More <i className="far fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About End */}
        {/* CTA Start */}
        <div className="section pt-0">
          <div className="container">
            <div className="row position-relative">
              <div className="col-lg-7 col-md-6">
                <div className="sigma_cta lg primary-bg">
                  <img
                    className="d-none d-lg-block"
                    src="assets/img/cta/3.png"
                    alt="cta"
                  />
                  <div className="sigma_cta-content">
                    <span className="fw-600 custom-secondary">
                      Need Help, Call Our HOTLINE!
                    </span>
                    <h4 className="text-white">+1 212-683-9756</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 position-relative">
                <div className="sigma_cta sm primary-bg">
                  <span className="sigma_cta-sperator d-none d-lg-flex">
                    or
                  </span>
                  <div className="sigma_cta-content">
                    <form method="post">
                      <label className="mb-0 text-white">
                        Temple Newsletter
                      </label>
                      <div className="sigma_search-adv-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter email address"
                          name="search"
                          defaultValue
                        />
                        <button type="submit" name="button">
                          <i className="far fa-envelope" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CTA End */}
        {/* Donation Start */}
        <div className="section section-padding pt-0">
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle">Donate To Help</p>
              <h4 className="title">Make a Donation to Help Community</h4>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/5.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Protecting Children</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $52,384</p>
                        <p>Goal: $85,000</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={84}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/6.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Donate Ngos</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $49,444</p>
                        <p>Goal: $78,000</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={65}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/7.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Feed The Poor</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $78,334</p>
                        <p>Goal: $96,400</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={94}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Donation End */}
        {/* Broadcast Start */}
        <div className="section section-padding pt-0">
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle">Watch Video</p>
              <h4 className="title">Our Live Broadcast</h4>
            </div>
            <div className="row sigma_broadcast-video">
              <div className="col-12 mb-5">
                <div className="row g-0 align-items-center">
                  <div className="col-lg-6">
                    <div className="sigma_video-popup-wrap">
                      <img src="assets/img/video-gallery/01.png" alt="video" />
                      <a
                        href="https://www.youtube.com/watch?v=TKnufs85hXk"
                        className="sigma_video-popup popup-youtube"
                      >
                        <i className="fas fa-play" />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_box m-0">
                      <p className="custom-primary mb-0 fw-600 fs-16">
                        Aug 12, 2024
                      </p>
                      <h4 className="title">
                        Serving the needs of the Hindu Community
                      </h4>
                      <p className="m-0">
                        We are a Hindu that belives in Lord Rama and Vishnu Deva
                        the followers and We are a Hindu that belives in Lord
                        Rama and Vishnu Deva. This is where you should start
                        Temple is place where hindu worship consectetur
                        adipisicing elit, sed do
                      </p>
                      <a
                        href=""
                        className="sigma_btn-custom section-button"
                      >
                        Watch Video
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="sigma_video-popup-wrap">
                  <img src="assets/img/video-gallery/1.png" alt="video" />
                  <a
                    href="https://www.youtube.com/watch?v=TKnufs85hXk"
                    className="sigma_video-popup popup-sm popup-youtube"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
                <h6 className="mb-0 mt-3">Varansi at Night</h6>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="sigma_video-popup-wrap">
                  <img src="assets/img/video-gallery/2.jpg" alt="video" />
                  <a
                    href="https://www.youtube.com/watch?v=TKnufs85hXk"
                    className="sigma_video-popup popup-sm popup-youtube"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
                <h6 className="mb-0 mt-3">Chaar Dhaam Yatra</h6>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="sigma_video-popup-wrap">
                  <img src="assets/img/video-gallery/3.jpg" alt="video" />
                  <a
                    href="https://www.youtube.com/watch?v=TKnufs85hXk"
                    className="sigma_video-popup popup-sm popup-youtube"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
                <h6 className="mb-0 mt-3">Mahashivratri Temple</h6>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="sigma_video-popup-wrap">
                  <img src="assets/img/video-gallery/4.jpg" alt="video" />
                  <a
                    href="https://www.youtube.com/watch?v=TKnufs85hXk"
                    className="sigma_video-popup popup-sm popup-youtube"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
                <h6 className="mb-0 mt-3">OM Mahashivratri</h6>
              </div>
            </div>
          </div>
        </div>
        {/* Broadcast End */}
        {/* Puja Start */}
        {/* <div className="section section-padding light-bg">
          <div className="container">
            <div className="section-title text-start flex-title">
              <div>
                <p className="subtitle">Puja</p>
                <h4 className="title mb-lg-0">Our Puja</h4>
              </div>
              <div className="text-center filter-items me-0 mb-0">
                <h5 className="active portfolio-trigger" data-filter="*">
                  All
                </h5>
                <h5 className="portfolio-trigger" data-filter=".coaching">
                  Durga Puja
                </h5>
                <h5 className="portfolio-trigger" data-filter=".stakeholder">
                  Raksha Bandhan
                </h5>
                <h5 className="portfolio-trigger" data-filter=".relations">
                  Holi
                </h5>
              </div>
            </div>
            <div className="portfolio-filter row">
              <div className="col-lg-4 coaching">
                <div className="sigma_portfolio-item">
                  <img src="assets/img/puja/1.jpg" alt="portfolio" />
                  <div className="sigma_portfolio-item-content">
                    <div className="sigma_portfolio-item-content-inner">
                      <h5>
                        <a href="#"> Durga Puja </a>
                      </h5>
                      <p className="blockquote bg-transparent">
                        Puja is the worship of the Lord, consectet ur
                        adipisicing elit, sed do eiusmod
                      </p>
                    </div>
                    <a href="#">
                      <i className="fal fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 strategy">
                <div className="sigma_portfolio-item">
                  <img src="assets/img/puja/2.jpg" alt="portfolio" />
                  <div className="sigma_portfolio-item-content">
                    <div className="sigma_portfolio-item-content-inner">
                      <h5>
                        <a href="#"> Raksha Bandhan </a>
                      </h5>
                      <p className="blockquote bg-transparent">
                        Puja is the worship of the Lord, consectet ur
                        adipisicing elit, sed do eiusmod
                      </p>
                    </div>
                    <a href="#">
                      <i className="fal fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 coaching strategy">
                <div className="sigma_portfolio-item">
                  <img src="assets/img/puja/3.jpg" alt="portfolio" />
                  <div className="sigma_portfolio-item-content">
                    <div className="sigma_portfolio-item-content-inner">
                      <h5>
                        <a href="#"> Janmashtmi </a>
                      </h5>
                      <p className="blockquote bg-transparent">
                        Puja is the worship of the Lord, consectet ur
                        adipisicing elit, sed do eiusmod
                      </p>
                    </div>
                    <a href="#">
                      <i className="fal fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 relations coaching">
                <div className="sigma_portfolio-item">
                  <img src="assets/img/puja/4.jpg" alt="portfolio" />
                  <div className="sigma_portfolio-item-content">
                    <div className="sigma_portfolio-item-content-inner">
                      <h5>
                        <a href="#"> Mahashivratri </a>
                      </h5>
                      <p className="blockquote bg-transparent">
                        Puja is the worship of the Lord, consectet ur
                        adipisicing elit, sed do eiusmod
                      </p>
                    </div>
                    <a href="#">
                      <i className="fal fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 stakeholder strategy">
                <div className="sigma_portfolio-item">
                  <img src="assets/img/puja/5.jpg" alt="portfolio" />
                  <div className="sigma_portfolio-item-content">
                    <div className="sigma_portfolio-item-content-inner">
                      <h5>
                        <a href="#"> Holi </a>
                      </h5>
                      <p className="blockquote bg-transparent">
                        Puja is the worship of the Lord, consectet ur
                        adipisicing elit, sed do eiusmod
                      </p>
                    </div>
                    <a href="#">
                      <i className="fal fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 stakeholder">
                <div className="sigma_portfolio-item">
                  <img src="assets/img/puja/6.jpg" alt="portfolio" />
                  <div className="sigma_portfolio-item-content">
                    <div className="sigma_portfolio-item-content-inner">
                      <h5>
                        <a href="#"> Diwali</a>
                      </h5>
                      <p className="blockquote bg-transparent">
                        Puja is the worship of the Lord, consectet ur
                        adipisicing elit, sed do eiusmod
                      </p>
                    </div>
                    <a href="#">
                      <i className="fal fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="section section-padding light-bg">
          <div className="container">
            {/* Section Title */}
            <div className="section-title text-start flex-title">
              <div>
                <p className="subtitle">Puja</p>
                <h4 className="title mb-lg-0">Our Puja</h4>
              </div>
              {/* Tabs */}
              <div className="text-center filter-items me-0 mb-0">
                <h5
                  className={`portfolio-trigger ${
                    activeFilter === "*" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("*")}
                >
                  All
                </h5>
                <h5
                  className={`portfolio-trigger ${
                    activeFilter === "coaching" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("coaching")}
                >
                  Durga Puja
                </h5>
                <h5
                  className={`portfolio-trigger ${
                    activeFilter === "strategy" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("strategy")}
                >
                  Raksha Bandhan
                </h5>
                <h5
                  className={`portfolio-trigger ${
                    activeFilter === "stakeholder" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("stakeholder")}
                >
                  Holi
                </h5>
              </div>
            </div>

            {/* Portfolio Items */}
            <div className="portfolio-filter row">
              {pujaItems
                .filter(
                  (item) =>
                    activeFilter === "*" || item.category.includes(activeFilter)
                )
                .map((item, index) => (
                  <div key={index} className={`col-lg-4 ${item.category}`}>
                    <div className="sigma_portfolio-item">
                      <img src={item.imgSrc} alt="portfolio" />
                      <div className="sigma_portfolio-item-content">
                        <div className="sigma_portfolio-item-content-inner">
                          <h5>
                            <a href="#">{item.title}</a>
                          </h5>
                          <p className="blockquote bg-transparent">
                            {item.description}
                          </p>
                        </div>
                        <a href="#">
                          <i className="fal fa-plus" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* Puja End */}
        {/* volunteers Start */}
        <div
          className="section section-padding bg-cover secondary-overlay bg-center bg-norepeat"
          style={{ backgroundImage: "url(assets/img/bg2.jpg)" }}
        >
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle text-white">Who</p>
              <h4 className="title text-white">Our Volunteers</h4>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/4.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        <a href="#" className="trigger-volunteers-socials">
                          <i className="fal fa-plus" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p className="text-white">Temple Pandit</p>
                      <h5 className="text-white">
                        <a href="">Rakesh K Pandey</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/6.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        <a href="#" className="trigger-volunteers-socials">
                          <i className="fal fa-plus" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p className="text-white">Temple Memember</p>
                      <h5 className="text-white">
                        <a href="#">Yesh Chopra</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/5.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        <a href="#" className="trigger-volunteers-socials">
                          <i className="fal fa-plus" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p className="text-white">Temple Memember</p>
                      <h5 className="text-white">
                        <a href="#">M Kapoor</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/3.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        <a href="#" className="trigger-volunteers-socials">
                          <i className="fal fa-plus" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p className="text-white">Temple Memember</p>
                      <h5 className="text-white">
                        <a href="#">Mohan Das</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* volunteers End */}
        {/* Testimonials Start */}
        <section className="section sigma_testimonial-sec style-4">
          <div className="container testimonial-section style-4">
            <div className="row">
              <div className="col-lg-5">
                <div className="section-title text-start">
                  <p className="subtitle">Testimonials</p>
                  <h4 className="title">What Our Members Say</h4>
                  <p>
                    We are a Hindu that belives in Lord Rama and Vishnu Deva the
                    followers and We are a Hindu that belives in Lord Rama and
                    Vishnu Deva. This is where you should start Temple is place
                    where hindu worship consectetur adipisicing elit, sed do
                    Temple is place where hindu worship consectetur adipisicing
                    elit, sed do
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="sigma_testimonial style-4">
                  <div className="sigma_testimonial-body">
                    <div className="sigma_testimonial-image d-none d-lg-block">
                      <div className="row justify-content-center align-items-center g-0">
                        <div className="col-md-3">
                          <img
                            src="assets/img/testimonials/c-img01.png"
                            alt="img"
                          />
                        </div>
                        <div className="col-md-5 ms-3">
                          <img
                            src="assets/img/testimonials/c-img04.png"
                            alt="img"
                          />
                        </div>
                        <div className="col-md-3 ms-3">
                          <img
                            src="assets/img/testimonials/c-img03.png"
                            alt="img"
                          />
                        </div>
                      </div>
                    </div>
                    <i className="flaticon-right-quote icon" />
                    <div className="sigma_testimonial-slider-1">
                      <Slider {...settings1}>
                        <div className="sigma_testimonial-slider-item">
                          <p>
                            If money help a man to do well to others, it is of
                            some value; but if not, it is simply a mass of evil,
                            and the sooner it is got rid of, the better.
                          </p>
                          <div className="sigma_testimonial-author">
                            <cite>Mukesh Singh</cite>
                          </div>
                        </div>
                        <div className="sigma_testimonial-slider-item">
                          <p>
                            If money help a man to do well to others, it is of
                            some value; but if not, it is simply a mass of evil,
                            and the sooner it is got rid of, the better.
                          </p>
                          <div className="sigma_testimonial-author">
                            <cite>Das Gupta</cite>
                          </div>
                        </div>
                        <div className="sigma_testimonial-slider-item">
                          <p>
                            If money help a man to do well to others, it is of
                            some value; but if not, it is simply a mass of evil,
                            and the sooner it is got rid of, the better.
                          </p>
                          <div className="sigma_testimonial-author">
                            <cite>R Patel</cite>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials End */}
        {/* Blog Start */}
        <div
          className="section section-padding primary-overlay bg-cover bg-center"
          style={{ backgroundImage: "url(assets/img/bg3.jpg)" }}
        >
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle text-white">Blog</p>
              <h4 className="title text-white">News Feed</h4>
            </div>
            <div className="row">
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/a1.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a href="#" className="sigma_post-category">
                          Temple
                        </a>
                        ,
                        <a href="#" className="sigma_post-category">
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/1.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/a2.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a href="#" className="sigma_post-category">
                          Temple
                        </a>
                        ,
                        <a href="#" className="sigma_post-category">
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">Reconstruct or new construct Temple</a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/2.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/a4.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a href="#" className="sigma_post-category">
                          Temple
                        </a>
                        ,
                        <a href="#" className="sigma_post-category">
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      <a href="#">Ensure child safety &amp; health in World</a>
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/1.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By
                        <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/*Footer*/}
        <Footer />
      </div>
    </div>
  );
}

export default Home;
