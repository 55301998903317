import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Faqs() {
  return (
    <div>
      <Header></Header>
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>FAQ</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    FAQ
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        {/* Categories Start */}
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="accordion with-gap" id="generalFAQExample">
                  <div className="card">
                    <div
                      className="card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      data-bs-target="#generalOne"
                      aria-expanded="true"
                      aria-controls="generalOne"
                    >
                      What is a Temple?
                    </div>
                    <div
                      id="generalOne"
                      className="collapse show"
                      data-bs-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor risus.
                        Vivamus magna justo, .People ask questions related to
                        Hinduism
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      data-bs-target="#generalTwo"
                      aria-expanded="false"
                      aria-controls="generalTwo"
                    >
                      Getting Started with an Temple
                    </div>
                    <div
                      id="generalTwo"
                      className="collapse"
                      data-bs-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor risus.
                        Vivamus magna justo, .People ask questions related to
                        Hinduism
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      data-bs-target="#generalThree"
                      aria-expanded="false"
                      aria-controls="generalThree"
                    >
                      Do i have the latest version?
                    </div>
                    <div
                      id="generalThree"
                      className="collapse"
                      data-bs-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor risus.
                        Vivamus magna justo, .People ask questions related to
                        Hinduism
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      data-bs-target="#generalSeven"
                      aria-expanded="false"
                      aria-controls="generalSeven"
                    >
                      How can I change header
                    </div>
                    <div
                      id="generalSeven"
                      className="collapse"
                      data-bs-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor risus.
                        Vivamus magna justo, .People ask questions related to
                        Hinduism
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="accordion with-gap" id="generalFAQExample2">
                  <div className="card">
                    <div
                      className="card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      data-bs-target="#generalFour"
                      aria-expanded="true"
                      aria-controls="generalFour"
                    >
                      How many times can I use a Temple?
                    </div>
                    <div
                      id="generalFour"
                      className="collapse show"
                      data-bs-parent="#generalFAQExample2"
                    >
                      <div className="card-body">
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor risus.
                        Vivamus magna justo, .People ask questions related to
                        Hinduism
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      data-bs-target="#generalFive"
                      aria-expanded="false"
                      aria-controls="generalFive"
                    >
                      How to migrate my website?
                    </div>
                    <div
                      id="generalFive"
                      className="collapse"
                      data-bs-parent="#generalFAQExample2"
                    >
                      <div className="card-body">
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor risus.
                        Vivamus magna justo, .People ask questions related to
                        Hinduism
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      data-bs-target="#generalSix"
                      aria-expanded="false"
                      aria-controls="generalSix"
                    >
                      How to migrate my website?
                    </div>
                    <div
                      id="generalSix"
                      className="collapse"
                      data-bs-parent="#generalFAQExample2"
                    >
                      <div className="card-body">
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor risus.
                        Vivamus magna justo, .People ask questions related to
                        Hinduism
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      data-bs-target="#generalEight"
                      aria-expanded="false"
                      aria-controls="generalEight"
                    >
                      Changing the site footer
                    </div>
                    <div
                      id="generalEight"
                      className="collapse"
                      data-bs-parent="#generalFAQExample2"
                    >
                      <div className="card-body">
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor risus.
                        Vivamus magna justo, .People ask questions related to
                        Hinduism
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Categories End */}
        {/* Form Start */}
        <div
          className="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat"
          style={{ backgroundImage: "url(assets/img/bg4.jpg)" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-lg-30">
                <form method="post">
                  <div className="form-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-user" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="First Name"
                          name="fname"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-user" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Last Name"
                          name="lname"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-pencil" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Subject"
                          name="subject"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-envelope" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Email Address"
                          name="email"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control transparent"
                          placeholder="Enter Message"
                          rows={4}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        className="sigma_btn-custom d-block w-100"
                        name="button"
                      >
                        Get a Quote <i className="far fa-arrow-right" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/1.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/2.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/3.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/4.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/5.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/6.png" alt="client" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Form End */}
      </>
      <Footer></Footer>
    </div>
  );
}

export default Faqs;
