import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function DonationArchive() {
  return (
    <>
      <Header> </Header>
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>Donation Archive</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Donation Archive
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        {/* donation Start */}
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/5.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Protecting Children</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $52,384</p>
                        <p>Goal: $85,000</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={84}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      {" "}
                      Donate{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/6.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Donate Ngos</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $49,444</p>
                        <p>Goal: $78,000</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={65}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      {" "}
                      Donate{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/7.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Feed Poors</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $78,334</p>
                        <p>Goal: $96,400</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={94}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      {" "}
                      Donate{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/8.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Empower Young People</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $35,984</p>
                        <p>Goal: $75,000</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={70}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      {" "}
                      Donate{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/9.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Donate Schools</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $52,384</p>
                        <p>Goal: $85,000</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={84}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      {" "}
                      Donate{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sigma_service style-2">
                  <div className="sigma_service-thumb">
                    <img src="assets/img/donation/10.jpg" alt="img" />
                  </div>
                  <div className="sigma_service-body">
                    <h5>
                      <a href="#">Protecting Children</a>
                    </h5>
                    <p>
                      Temple is place where hindu worship consectetur
                      adipisicing elit, sed do
                    </p>
                    <div className="sigma_service-progress">
                      <div className="progress-content">
                        <p>Raised: $45,684</p>
                        <p>Goal: $90,000</p>
                      </div>
                      <div className="sigma_progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={65}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                    <a href="#" className="sigma_btn-custom">
                      {" "}
                      Donate{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* donation End */}
        {/* Clients Start */}
        <div className="section section-padding primary-bg pattern-map">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="sigma_client">
                      <img src="assets/img/clients/1.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sigma_client">
                      <img src="assets/img/clients/2.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sigma_client">
                      <img src="assets/img/clients/3.png" alt="client" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/4.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/5.png" alt="client" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Clients End */}
        {/* CTA Start */}
        <div className="section section-padding">
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle">WAYS WE CAN HELP</p>
              <h4 className="title">Angels Ready To Help</h4>
            </div>
            <div className="row align-items-center position-relative">
              <div className="col-md-6">
                <div className="sigma_cta secondary-bg">
                  <img
                    className="d-none d-lg-block"
                    src="assets/img/cta/1.png"
                    alt="cta"
                  />
                  <div className="sigma_cta-content">
                    <span className="fw-600 custom-primary">
                      Need Help, Call Our HOTLINE!
                    </span>
                    <h4 className="text-white">+1 212-683-9756</h4>
                  </div>
                </div>
              </div>
              <span className="sigma_cta-sperator d-none d-lg-flex">or</span>
              <div className="col-md-6">
                <div className="sigma_cta primary-bg">
                  <div className="sigma_cta-content">
                    <form method="post">
                      <label className="mb-0 text-white">
                        Temple Newsletter
                      </label>
                      <div className="sigma_search-adv-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter email address"
                          name="search"
                          defaultValue=""
                        />
                        <button type="submit" name="button">
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </form>
                  </div>
                  <img
                    className="d-none d-lg-block"
                    src="assets/img/cta/2.png"
                    alt="cta"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CTA End */}
      </>
      <Footer></Footer>
    </>
  );
}

export default DonationArchive;
