import React from "react";
import { Link, NavLink } from "react-router-dom";

function Footer() {
  return (
    <div>
      {/* partial:partia/__footer.html */}
      <footer className="sigma_footer footer-2 sigma_footer-dark">
        {/* Middle Footer */}
        <div className="sigma_footer-middle">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">About Us</h5>
                <p className="mb-4">
                  You need to be sure there isn’t anything embarrassing hidden
                  in the middle of text.
                </p>
                <div className="d-flex align-items-center justify-content-md-start justify-content-center">
                  <i className="far fa-phone custom-primary me-3" />
                  <span>987-987-930-302</span>
                </div>
                <div className="d-flex align-items-center justify-content-md-start justify-content-center mt-2">
                  <i className="far fa-envelope custom-primary me-3" />
                  <span>info@example.com</span>
                </div>
                <div className="d-flex align-items-center justify-content-md-start justify-content-center mt-2">
                  <i className="far fa-map-marker custom-primary me-3" />
                  <span>14/A, Poor Street City Tower, New York USA</span>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">Information</h5>
                <ul>
                  <li>
                    <NavLink to="/Service">Temple</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Volunteer">Volunteers</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Donation">Donation</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Puja">Puja</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">Others</h5>
                <ul>
                  <li>
                    <NavLink to="/Shop">Shop</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Events">Events</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Donation">Donation</NavLink>
                  </li>
                  <li>
                    <NavLink to="/Contact">Contact Us</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 d-none d-lg-block footer-widget widget-recent-posts">
                <h5 className="widget-title">Recent Posts</h5>
                <article className="sigma_recent-post">
                  <a href="#">
                    <img src="assets/img/blog/sm/1.jpg" alt="post" />
                  </a>
                  <div className="sigma_recent-post-body">
                    <a href="#">
                      <i className="far fa-calendar" /> May 20, 2024
                    </a>
                    <h6>
                      <a href="#">
                        As we've all discovered by now, the world can change
                      </a>
                    </h6>
                  </div>
                </article>
                <article className="sigma_recent-post">
                  <a href="#">
                    <img src="assets/img/blog/sm/2.jpg" alt="post" />
                  </a>
                  <div className="sigma_recent-post-body">
                    <a href="#">
                      <i className="far fa-calendar" /> May 20, 2024
                    </a>

                    <h6>
                      {" "}
                      <a href="#">Testimony love offering so blessed</a>
                    </h6>
                  </div>
                </article>
                <article className="sigma_recent-post">
                  <a href="#">
                    <img src="assets/img/blog/sm/3.jpg" alt="post" />
                  </a>
                  <div className="sigma_recent-post-body">
                    <a href="#">
                      <i className="far fa-calendar" /> May 20, 2024
                    </a>
                    <h6>
                      <a href="#">
                        As we've all discovered by now, the world can change
                      </a>
                    </h6>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="sigma_footer-bottom">
          <div className="container-fluid">
            <div className="sigma_footer-copyright">
              <p>
                Copyright © Maharatri -
                <a href="#" className="text-white">
                  2024
                </a>
              </p>
            </div>
            <div className="sigma_footer-logo">
              <img src="assets/img/logo.png" alt="logo" />
            </div>
            <ul className="sigma_sm square">
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      {/* partial */}
    </div>
  );
}

export default Footer;
