import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function Header() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const [show, setshow] = useState(false);

  const shows =
    show == true
      ? "sigma_aside sigma_aside-left open"
      : "sigma_aside sigma_aside-left";

  const [show1, setshow1] = useState(false);

  let shows1 =
    show1 == true
      ? "sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg open"
      : "sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg";

  const Showing1 = () => {
    setshow1(false);
  };

  const Showing = () => {
    setshow(false);
  };

  return (
    <>
      {/* partial:partia/__sidenav.html */}
      <aside className={shows1}>
        <div
          className="sidebar"
          style={{ display: show1 == true ? "block" : "none" }}
        >
          <div className="sidebar-widget widget-logo">
            <img src="assets/img/logo.png" className="mb-30" alt="img" />
            <p>
              Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
              Donec rutrum congue leo eget malesuada. Praesent sapien massa,
              convallis a pellentesque nec, egestas non nisi.
            </p>
          </div>
          {/* Instagram Start */}
          <div className="sidebar-widget widget-ig">
            <h5 className="widget-title">Instagram</h5>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/1.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/2.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/3.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/4.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/5.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/6.jpg" alt="ig" />
                </a>
              </div>
            </div>
          </div>
          {/* Instagram End */}
          {/* Social Media Start */}
          <div className="sidebar-widget">
            <h5 className="widget-title">Follow Us</h5>
            <div className="sigma_post-share">
              <ul className="sigma_sm square light">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Social Media End */}
        </div>
      </aside>
      <div
        className="sigma_aside-overlay aside-trigger-right"
        onClick={() => {
          Showing1();
        }}
      />
      {/* partial */}
      {/* partial:partia/__mobile-nav.html */}
      <aside className={shows}>
        <a className="navbar-brand" href="">
          <img src="assets/img/logo.png" alt="logo" />
        </a>
        {/* Menu */}
        <ul>
          <li className="menu-item">
            <NavLink to="/">Home</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Service">Temple</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/about">About Us</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Contact">Contact Us</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Donation">Donations</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Events">Events</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Puja">Puja</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Shop">Shop</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Events">Events</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Volunteer">Volunteer</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/Faqs">Faqs</NavLink>
          </li>
        </ul>
      </aside>
      <div
        className="sigma_aside-overlay aside-trigger-left"
        onClick={() => {
          Showing();
        }}
      />
      {/* partial */}
      {/* partial:partia/__header.html */}
      <header className="sigma_header header-4 can-sticky header-absolute">
        {/* Top Header Start */}
        <div className="sigma_header-top">
          <div className="container-fluid">
            <div className="sigma_header-top-inner">
              <ul className="sigma_header-top-links">
                <li className="menu-item">
                  <a href="tel:+123456789">
                    <i className="fal fa-phone" /> (+91) 9912 345 678
                  </a>
                </li>
                <li className="menu-item">
                  <a href="mailto:info@example.com">
                    <i className="fal fa-envelope" /> info@domain.com
                  </a>
                </li>
              </ul>
              <div className="sigma_header-middle">
                <div className="navbar p-0 shadow-none bg-transparent">
                  <ul className="navbar-nav">
                    <li className="menu-item">
                      <NavLink to="/Events">Events</NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink to="/Volunteer">Volunteer</NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink to="/Faqs">Faqs</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <ul className="sigma_sm">
                <li className="d-flex align-items-center">
                  <a href="" className="live">
                    <NavLink to="/Live">
                      <i className="fa fa-circle" />
                      Live
                    </NavLink>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Top Header End */}
        {/* Middle Header Start */}
        <div className="sigma_header-middle">
          <div className="container-fluid">
            <nav className="navbar">
              {/* Logo Start */}
              <div className="sigma_logo-wrapper">
                <a className="navbar-brand" href="">
                  <NavLink to="/">
                    <img src="assets/img/logo.png" alt="logo" />
                  </NavLink>
                </a>
              </div>
              {/* Logo End */}
              {/* Menu */}
              <ul className="navbar-nav">
                <li className="menu-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/Service">Temple</NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/about">About Us</NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/Contact">Contact Us</NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/Donation">Donations</NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/Events">Events</NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/Puja">Puja</NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/Shop">Shop</NavLink>
                </li>
              </ul>
              {/* Controls */}
              <div className="sigma_header-controls style-2">
                <ul className="sigma_header-controls-inner">
                  <li className="sigma_header-cart style-2">
                    <NavLink to="/Cart">
                      <i className="fal fa-shopping-bag" />
                    </NavLink>

                    <span className="number">1</span>
                    <ul className="cart-dropdown">
                      <li>
                        <a href="#" className="sigma_cart-product-wrapper">
                          <img src="assets/img/products/1.jpg" alt="prod1" />
                          <div className="sigma_cart-product-body">
                            <h6>Gita Book</h6>
                            <div className="sigma_product-price justify-content-start">
                              <span>29$</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="sigma_cart-product-wrapper">
                          <img src="assets/img/products/5.jpg" alt="prod1" />
                          <div className="sigma_cart-product-body">
                            <h6>Ramayana Book</h6>
                            <div className="sigma_product-price justify-content-start">
                              <span>29$</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="sigma_cart-product-wrapper">
                          <img src="assets/img/products/4.jpg" alt="prod1" />
                          <div className="sigma_cart-product-body">
                            <h6>Mahabharat Book</h6>
                            <div className="sigma_product-price justify-content-start">
                              <span>29$</span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="sigma_header-wishlist style-2">
                    <NavLink to="/Wishlist">
                      <i className="fal fa-heart" />
                    </NavLink>

                    <ul className="cart-dropdown">
                      <li>
                        <p className="mb-0">You don't have any items</p>
                      </li>
                    </ul>
                  </li>
                  {/* Desktop Toggler */}
                  <li
                    className="aside-toggler style-2 aside-trigger-right desktop-toggler"
                    onClick={() => {
                      setshow1(!show1);
                    }}
                  >
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                  </li>
                  {/* Mobile Toggler */}
                  <li
                    className="aside-toggler style-2 aside-trigger-left"
                    onClick={() => {
                      setshow(!show);
                    }}
                  >
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        {/* Middle Header End */}
      </header>
    </>
  );
}

export default Header;
