import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function ShopDetails() {
  return (
    <>
      <Header> </Header>
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>Product Details</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Product Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        {/* Product Content Start */}
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="sigma_product-single-thumb">
                  <div className="slider">
                    <img src="assets/img/products/0.jpg" alt="product" />
                    <img src="assets/img/products/1.jpg" alt="product" />
                    <img src="assets/img/products/2.jpg" alt="product" />
                    <img src="assets/img/products/3.jpg" alt="product" />
                  </div>
                  <div className="slider-nav">
                    <img src="assets/img/products/0.jpg" alt="product" />
                    <img src="assets/img/products/1.jpg" alt="product" />
                    <img src="assets/img/products/2.jpg" alt="product" />
                    <img src="assets/img/products/3.jpg" alt="product" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sigma_product-single-content">
                  <div className="sigma_rating-wrapper">
                    <div className="sigma_rating">
                      <i className="far fa-star active" />
                      <i className="far fa-star active" />
                      <i className="far fa-star active" />
                      <i className="far fa-star active" />
                      <i className="far fa-star" />
                    </div>
                    <span>255 Reviews</span>
                  </div>
                  <h4 className="entry-title">Gita Book</h4>
                  <div className="sigma_product-price">
                    <span>352$</span>
                    <span>245$</span>
                  </div>
                  <p>
                    <strong className="me-2">
                      Interested: <span>05</span>
                    </strong>
                    <strong>
                      Availablity: <span>In Stock</span>
                    </strong>
                  </p>
                  <p className="sigma_product-excerpt">
                    All Religious Books are available in Temple Stores. Our
                    mission is to share the Good of Hinduism, Loving, Faith and
                    Serving.
                  </p>
                  <form className="sigma_product-atc-form">
                    <div className="sigma_product-variation-wrapper">
                      <div className="sigma_product-radio form-group">
                        <label>
                          <input
                            type="radio"
                            name="size"
                            defaultValue=""
                            defaultChecked=""
                          />
                          <span>XS</span>
                        </label>
                        <label>
                          <input type="radio" name="size" defaultValue="" />
                          <span>S</span>
                        </label>
                        <label>
                          <input type="radio" name="size" defaultValue="" />
                          <span>M</span>
                        </label>
                        <label>
                          <input type="radio" name="size" defaultValue="" />
                          <span>L</span>
                        </label>
                        <label>
                          <input type="radio" name="size" defaultValue="" />
                          <span>XL</span>
                        </label>
                      </div>
                    </div>
                    <div className="qty-outter">
                      <a
                        href="#"
                        className="sigma_btn-custom"
                      >
                        Buy Now
                      </a>
                      <div className="qty-inner">
                        <h6>Qty:</h6>
                        <div className="qty">
                          <span className="qty-subtract">
                            <i className="fa fa-minus" />
                          </span>
                          <input type="text" name="qty" defaultValue={1} />
                          <span className="qty-add">
                            <i className="fa fa-plus" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* Post Meta Start */}
                  <div className="sigma_post-single-meta">
                    <div className="sigma_post-single-meta-item sigma_post-share">
                      <h6>Share</h6>
                      <ul className="sigma_sm">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-youtube" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="sigma_post-single-meta-item">
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Compare">
                          <i className="far fa-signal-4" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Post Meta End */}
                  <h6>Guaranteed Safe Checkout</h6>
                  <img src="assets/img/payments.png" alt="payments" />
                </div>
              </div>
              {/* Sidebar Start */}
              <div className="col-lg-4">
                <div className="sidebar">
                  {/* Tags Start */}
                  <div className="sidebar-widget widget-categories">
                    <h5 className="widget-title">Our Categories</h5>
                    <ul className="sidebar-widget-list">
                      <li>
                        <a href="#">
                          {" "}
                          Avatars <span>32</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          Festivals <span>22</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          Mahabharat <span>17</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          Mythology <span>05</span>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Tags End */}
                  {/* Filter: Price Start */}
                  <div className="sidebar-widget">
                    <h5 className="widget-title">Price</h5>
                    <input
                      type="text"
                      className="js-range-slider"
                      name="freshness_range"
                      defaultValue=""
                      data-type="double"
                      data-min={0}
                      data-max={500}
                      data-from={10}
                      data-to={100}
                      data-grid="true"
                      data-postfix=" $"
                    />
                  </div>
                  {/* Filter: Price End */}
                </div>
              </div>
              {/* Sidebar End */}
            </div>
          </div>
        </div>
        {/* Product Content End */}
        {/* Additional Information Start */}
        <div className="section pt-0">
          <div className="container">
            <div className="sigma_product-additional-info">
              <ul className="nav" id="bordered-tab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="tab-product-desc-tab"
                    data-bs-toggle="pill"
                    href="#tab-product-desc"
                    role="tab"
                    aria-controls="tab-product-desc"
                    aria-selected="true"
                  >
                    Description
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="tab-product-info-tab"
                    data-bs-toggle="pill"
                    href="#tab-product-info"
                    role="tab"
                    aria-controls="tab-product-info"
                    aria-selected="false"
                  >
                    Additional Information
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="tab-product-reviews-tab"
                    data-bs-toggle="pill"
                    href="#tab-product-reviews"
                    role="tab"
                    aria-controls="tab-product-reviews"
                    aria-selected="false"
                  >
                    Reviews
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="bordered-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="tab-product-desc"
                  role="tabpanel"
                  aria-labelledby="tab-product-desc-tab"
                >
                  <h4>Description</h4>
                  <p>
                    All Religious Books are available in Temple Stores. Our
                    mission is to share the Good of Hinduism, Loving, Faith and
                    Serving., non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica
                  </p>
                  <p>
                    All Religious Books are available in Temple Stores. Our
                    mission is to share the Good of Hinduism, Loving, Faith and
                    Serving., non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica
                  </p>
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-product-info"
                  role="tabpanel"
                  aria-labelledby="tab-product-info-tab"
                >
                  <h4>Additional Information</h4>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Attributes</th>
                        <th scope="col">Values</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Color</strong>
                        </td>
                        <td>blue, red, yellow, green</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Material</strong>
                        </td>
                        <td>wood, plastic, stainless steel</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-product-reviews"
                  role="tabpanel"
                  aria-labelledby="tab-product-reviews-tab"
                >
                  <h4>Leave a Review</h4>
                  <div className="sigma_rating-wrapper">
                    <div className="sigma_rating">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                    </div>
                    <span>Your Review</span>
                  </div>
                  {/* Review Form start */}
                  <div className="comment-form">
                    <form method="post">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            name="fname"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-md-12 form-group">
                          <textarea
                            className="form-control"
                            placeholder="Type your comment..."
                            name="comment"
                            rows={7}
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="sigma_btn-custom"
                        name="button"
                      >
                        Post Review
                      </button>
                    </form>
                  </div>
                  {/* Review Form End */}
                  {/* Reviews Start */}
                  <div className="comments-list">
                    <ul>
                      <li className="comment-item">
                        <img
                          src="assets/img/volunteers/1.jpg"
                          alt="comment author"
                        />
                        <div className="comment-body">
                          <h5>Robert John</h5>
                          <div className="sigma_rating">
                            <i className="fa fa-star active" />
                            <i className="fa fa-star active" />
                            <i className="fa fa-star active" />
                            <i className="fa fa-star active" />
                            <i className="fa fa-star" />
                          </div>
                          <p>
                            Leverage agile frameworks to provide a robust
                            synopsis for high level overviews. Iterative
                            approaches to corporate strategy foster
                            collaborative thinking to further the overall value
                            proposition.
                          </p>
                          <a href="#" className="btn-link">
                            Reply <i className="far fa-reply" />
                          </a>
                          <span>
                            <i className="far fa-clock" /> January 13 2024
                          </span>
                        </div>
                      </li>
                      <li className="comment-item">
                        <img
                          src="assets/img/volunteers/2.jpg"
                          alt="comment author"
                        />
                        <div className="comment-body">
                          <h5>Christine Hill</h5>
                          <div className="sigma_rating">
                            <i className="fa fa-star active" />
                            <i className="fa fa-star active" />
                            <i className="fa fa-star active" />
                            <i className="fa fa-star active" />
                            <i className="fa fa-star active" />
                          </div>
                          <p>
                            Leverage agile frameworks to provide a robust
                            synopsis for high level overviews. Iterative
                            approaches
                          </p>
                          <a href="#" className="btn-link">
                            Reply <i className="far fa-reply" />
                          </a>
                          <span>
                            <i className="far fa-clock" /> January 13 2024
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* Reviews End */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Additional Information End */}
      </>
      <Footer></Footer>
    </>
  );
}

export default ShopDetails;
