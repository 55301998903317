import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Service() {
  return (
    <>
      <Header> </Header>
      {/* partial:partia/__subheader.html */}
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>Temple</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a className="btn-link" href="#">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Temple
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* partial */}
      {/* holi Start */}
      <div className="section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <a href="#" className="sigma_service style-2">
                <div className="sigma_service-thumb">
                  <img src="assets/img/service/5.jpg" alt="img" />
                  <i className="flaticon-hindu" />
                </div>
                <div className="sigma_service-body">
                  <h5>Darshan</h5>
                  <p>
                    Temple is place where hindu worship consectetur adipisicing
                    elit, sed do
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <a href="#" className="sigma_service style-2">
                <div className="sigma_service-thumb">
                  <img src="assets/img/service/6.jpg" alt="img" />
                  <i className="flaticon-powder-1" />
                </div>
                <div className="sigma_service-body">
                  <h5>Puja</h5>
                  <p>
                    Temple is place where hindu worship consectetur adipisicing
                    elit, sed do
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <a href="#" className="sigma_service style-2">
                <div className="sigma_service-thumb">
                  <img src="assets/img/service/7.jpg" alt="img" />
                  <i className="flaticon-hindu-2" />
                </div>
                <div className="sigma_service-body">
                  <h5>Prashad</h5>
                  <p>
                    Temple is place where hindu worship consectetur adipisicing
                    elit, sed do
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <a href="#" className="sigma_service style-2">
                <div className="sigma_service-thumb">
                  <img src="assets/img/service/8.jpg" alt="img" />
                  <i className="flaticon-pooja" />
                </div>
                <div className="sigma_service-body">
                  <h5>Car Puja</h5>
                  <p>
                    Temple is place where hindu worship consectetur adipisicing
                    elit, sed do
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <a href="#" className="sigma_service style-2">
                <div className="sigma_service-thumb">
                  <img src="assets/img/service/9.jpg" alt="img" />
                  <i className="flaticon-arti" />
                </div>
                <div className="sigma_service-body">
                  <h5>Bhoomi Puja</h5>
                  <p>
                    Temple is place where hindu worship consectetur adipisicing
                    elit, sed do
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <a href="#" className="sigma_service style-2">
                <div className="sigma_service-thumb">
                  <img src="assets/img/service/10.jpg" alt="img" />
                  <i className="flaticon-powder" />
                </div>
                <div className="sigma_service-body">
                  <h5>Marriage</h5>
                  <p>
                    Temple is place where hindu worship consectetur adipisicing
                    elit, sed do
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* holi End */}
      {/* Clients Start */}
      <div className="section section-padding primary-bg pattern-map">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-4">
                  <div className="sigma_client">
                    <img src="assets/img/clients/1.png" alt="client" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sigma_client">
                    <img src="assets/img/clients/2.png" alt="client" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sigma_client">
                    <img src="assets/img/clients/3.png" alt="client" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="sigma_client">
                    <img src="assets/img/clients/4.png" alt="client" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sigma_client">
                    <img src="assets/img/clients/5.png" alt="client" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Clients End */}
      {/* CTA Start */}
      <div className="section section-padding">
        <div className="container">
          <div className="section-title text-center">
            <p className="subtitle">WAYS WE CAN HELP</p>
            <h4 className="title">Angels Ready To Help</h4>
          </div>
          <div className="row align-items-center position-relative">
            <div className="col-md-6">
              <div className="sigma_cta primary-bg">
                <img
                  className="d-none d-lg-block"
                  src="assets/img/cta/1.png"
                  alt="cta"
                />
                <div className="sigma_cta-content">
                  <span className="fw-600 custom-secondary">
                    Need Help, Call Our HOTLINE!
                  </span>
                  <h4 className="text-white">+1 212-683-9756</h4>
                </div>
              </div>
            </div>
            <span className="sigma_cta-sperator d-none d-lg-flex">or</span>
            <div className="col-md-6">
              <div className="sigma_cta primary-bg">
                <div className="sigma_cta-content">
                  <form method="post">
                    <label className="mb-0 text-white">Temple Newsletter</label>
                    <div className="sigma_search-adv-input">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email address"
                        name="search"
                        defaultValue
                      />
                      <button type="submit" name="button">
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </form>
                </div>
                <img
                  className="d-none d-lg-block"
                  src="assets/img/cta/2.png"
                  alt="cta"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>{" "}
    </>
  );
}

export default Service;
