import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function VolunteerDetail() {
  return (
    <>
      <Header></Header>
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>Volunteer Details</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Volunteer Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        {/* Post Content Start */}
        <div className="section sigma_post-single">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="entry-content">
                  <div className="sigma_volunteer-detail mb-5">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="sigma_member-image style-1">
                          <img
                            src="assets/img/volunteers/pastor.jpg"
                            className="mb-0"
                            alt="volunteer"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="sigma_volunteer-detail-inner mt-5 mt-lg-0 ps-0 ps-lg-4">
                          <h3 className="sigma_member-name">Yeshvant Parsad</h3>
                          <span className="sigma_volunteer-detail-category">
                            Pandit
                          </span>
                          <ul className="sigma_volunteer-detail-info">
                            <li>
                              <i className="fas fa-phone" />
                              <span className="title">Phone:</span>(123)
                              456-7890 8, +987 868 6578 648
                            </li>
                            <li>
                              <i className="fas fa-envelope" />
                              <span className="title">Email:</span>
                              info@example.com, example@example.com
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt" />
                              <span className="title">Address Info:</span>24
                              Fifth st., New York, US
                            </li>
                          </ul>
                          <ul className="sigma_volunteer-detail-info">
                            <li>
                              <i className="fab fa-facebook-f" />
                              <span className="title">Facebook:</span>
                              <a href="#"> www.facebook.com/Kathy</a>
                            </li>
                            <li>
                              <i className="fab fa-twitter" />
                              <span className="title">Twitter:</span>
                              <a href="#"> www.twitter.com/Kathy</a>
                            </li>
                            <li>
                              <i className="fab fa-instagram" />
                              <span className="title">Instagram:</span>
                              <a href="#"> www.instagram.com/Kathy</a>
                            </li>
                            <li>
                              <i className="fab fa-linkedin" />
                              <span className="title">Linkedin:</span>
                              <a href="#"> www.linkedin.com/Kathy</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4>About Yeshvant Parsad</h4>
                  <p>
                    Our mission is to share the Good of Hinduism, Loving, Faith
                    and Serving. People ask questions related to Hinduism.
                    Temple is a place where Hindu worship our Bhagwan Ram,
                    Shiva, Vishnu, Krishna etc. galley of type and scrambled it
                    to make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting
                    Some Hindu teachers insist that believing in rebirth is
                    necessary for living an ethical life. Their concern is that
                    if there is no fear of karmic repercussions in future
                    lifetimes.
                  </p>
                  <p>
                    Temple is a place where Hindu worship our Bhagwan Ram,
                    Shiva, Vishnu, Krishna etc. Proin eget tortor industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type, People ask questions related
                    to Hinduism. specimen book.Some Hindu teachers insist that
                    believing in rebirth is necessary for living an ethical
                    life. Their concern is that if there is no fear of karmic
                    repercussions in future lifetimes,
                  </p>
                  <hr />
                  <h4>Skills</h4>
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        Temple is a place where Hindu worship our Bhagwan Ram,
                        Shiva, Vishnu, Krishna etc. Proin eget tortor industry's
                        standard dummy text ever since the 1500s, when an
                        unknown printer took a galley of type, People ask
                        questions related to Hinduism. specimen book.people
                        would likewise be unethical. Our mission is to share the
                        Good of Hinduism, Loving, Faith and Serving. People ask
                        questions related to Hinduism. to make a type specimen
                        book.I find this argument as sad as the argument that
                        without a belief in God people would likewise be
                        unethical.
                      </p>
                      <p className="m-0">
                        Some Hindu teachers insist that believing in rebirth is
                        necessary for living an ethical life. Their concern is
                        that if there is no fear of karmic repercussions in
                        future lifetimes
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <div className="mt-4 mt-lg-0">
                        <div className="sigma_progress">
                          <h6>Charity</h6>
                          <span className="sigma_progress-count">84%</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow={84}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              <span />
                              <span />
                            </div>
                          </div>
                        </div>
                        <div className="sigma_progress with-secondary">
                          <h6>Helped</h6>
                          <span className="sigma_progress-count">70%</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow={70}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              <span />
                              <span />
                            </div>
                          </div>
                        </div>
                        <div className="sigma_progress">
                          <h6>Donations</h6>
                          <span className="sigma_progress-count">85%</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow={85}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              <span />
                              <span />
                            </div>
                          </div>
                        </div>
                        <div className="sigma_progress">
                          <h6>Food</h6>
                          <span className="sigma_progress-count">92%</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow={92}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              <span />
                              <span />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Post Content End */}
        {/* Form Start */}
        <div
          className="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat"
          style={{ backgroundImage: "url(assets/img/bg1.jpg)" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-lg-30">
                <form method="post">
                  <div className="form-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-user" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="First Name"
                          name="fname"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-user" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Last Name"
                          name="lname"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-pencil" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Subject"
                          name="subject"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-envelope" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Email Address"
                          name="email"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control transparent"
                          placeholder="Enter Message"
                          rows={4}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        className="sigma_btn-custom d-block w-100"
                        name="button"
                      >
                        Get a Quote <i className="far fa-arrow-right" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/1.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/2.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/3.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/4.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/5.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/6.png" alt="client" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Form End */}
      </>
      <Footer></Footer>
    </>
  );
}

export default VolunteerDetail;
