import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Cart() {
  return (
    <>
      <Header> </Header>
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>Cart</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Cart
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        {/*Cart Start */}
        <div className="section">
          <div className="container">
            {/* Cart Table Start */}
            <table className="sigma_responsive-table">
              <thead>
                <tr>
                  <th className="remove-item" />
                  <th>Product</th>
                  <th>Price</th>
                  <th>Qunantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="remove">
                    <button
                      type="button"
                      className="close-btn close-danger remove-from-cart"
                    >
                      <span />
                      <span />
                    </button>
                  </td>
                  <td data-title="Product">
                    <div className="sigma_cart-product-wrapper">
                      <img src="assets/img/products/1.jpg" alt="prod1" />
                      <div className="sigma_cart-product-body">
                        <h6>
                          <a href="#">Gita Book</a>
                        </h6>
                        <p>2 Pieces</p>
                      </div>
                    </div>
                  </td>
                  <td data-title="Price">
                    <strong>12.99$</strong>
                  </td>
                  <td className="quantity" data-title="Quantity">
                    <input
                      type="number"
                      className="qty form-control"
                      defaultValue={1}
                    />
                  </td>
                  <td data-title="Total">
                    <strong>12.99$</strong>
                  </td>
                </tr>
                <tr>
                  <td className="remove">
                    <button
                      type="button"
                      className="close-btn close-danger remove-from-cart"
                    >
                      <span />
                      <span />
                    </button>
                  </td>
                  <td data-title="Product">
                    <div className="sigma_cart-product-wrapper">
                      <img src="assets/img/products/5.jpg" alt="prod1" />
                      <div className="sigma_cart-product-body">
                        <h6>
                          <a href="#">Ramayana Book</a>
                        </h6>
                        <p>1 Piece</p>
                      </div>
                    </div>
                  </td>
                  <td data-title="Price">
                    <strong>9.99$</strong>
                  </td>
                  <td className="quantity" data-title="Quantity">
                    <input
                      type="number"
                      className="qty form-control"
                      defaultValue={1}
                    />
                  </td>
                  <td data-title="Total">
                    <strong>9.99$</strong>
                  </td>
                </tr>
                <tr>
                  <td className="remove">
                    <button
                      type="button"
                      className="close-btn close-danger remove-from-cart"
                    >
                      <span />
                      <span />
                    </button>
                  </td>
                  <td data-title="Product">
                    <div className="sigma_cart-product-wrapper">
                      <img src="assets/img/products/4.jpg" alt="prod1" />
                      <div className="sigma_cart-product-body">
                        <h6>
                          <a href="#">Mahabharat Book</a>
                        </h6>
                        <p>3 Pieces</p>
                      </div>
                    </div>
                  </td>
                  <td data-title="Price">
                    <strong>13.99$</strong>
                  </td>
                  <td className="quantity" data-title="Quantity">
                    <input
                      type="number"
                      className="qty form-control"
                      defaultValue={1}
                    />
                  </td>
                  <td data-title="Total">
                    <strong>13.99$</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* Cart Table End */}
            {/* Coupon Code Start */}
            <div className="row">
              <div className="col-lg-5">
                <div className="form-group mb-0">
                  <div className="input-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Coupon Code"
                      aria-label="Coupon Code"
                    />
                    <div className="input-group-append">
                      <button
                        className="sigma_btn-custom shadow-none"
                        type="button"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Coupon Code End */}
          </div>
        </div>
        {/* Cart End */}
        {/* partial:partia/__footer.html */}
        <footer className="sigma_footer footer-2 sigma_footer-dark">
          {/* Middle Footer */}
          <div className="sigma_footer-middle">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 footer-widget">
                  <h5 className="widget-title">About Us</h5>
                  <p className="mb-4">
                    You need to be sure there isn’t anything embarrassing hidden
                    in the middle of text.
                  </p>
                  <div className="d-flex align-items-center justify-content-md-start justify-content-center">
                    <i className="far fa-phone custom-primary me-3" />
                    <span>987-987-930-302</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-md-start justify-content-center mt-2">
                    <i className="far fa-envelope custom-primary me-3" />
                    <span>info@example.com</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-md-start justify-content-center mt-2">
                    <i className="far fa-map-marker custom-primary me-3" />
                    <span>14/A, Poor Street City Tower, New York USA</span>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12 footer-widget">
                  <h5 className="widget-title">Information</h5>
                  <ul>
                    <li>
                      <a href="#">Puja</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#">Temple</a>
                    </li>
                    <li>
                      <a href="#">Holis</a>
                    </li>
                    <li>
                      <a href="#">Volunteers</a>
                    </li>
                    <li>
                      <a href="#">Donation</a>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12 footer-widget">
                  <h5 className="widget-title">Others</h5>
                  <ul>
                    <li>
                      <a href="#">Shop</a>
                    </li>
                    <li>
                      <a href="#">Checkout</a>
                    </li>
                    <li>
                      <a href="#">Donation</a>
                    </li>
                    <li>
                      <a href="#">Contact Us</a>
                    </li>
                    <li>
                      <a href="#">Blog</a>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 d-none d-lg-block footer-widget widget-recent-posts">
                  <h5 className="widget-title">Recent Posts</h5>
                  <article className="sigma_recent-post">
                    <a href="#">
                      <img src="assets/img/blog/sm/1.jpg" alt="post" />
                    </a>
                    <div className="sigma_recent-post-body">
                      <a href="#">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                      <h6>
                        <a href="#">
                          As we've all discovered by now, the world can change
                        </a>
                      </h6>
                    </div>
                  </article>
                  <article className="sigma_recent-post">
                    <a href="#">
                      <img src="assets/img/blog/sm/2.jpg" alt="post" />
                    </a>
                    <div className="sigma_recent-post-body">
                      <a href="#">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                      <h6>
                        <a href="#">
                          Testimony love offering so blessed
                        </a>
                      </h6>
                    </div>
                  </article>
                  <article className="sigma_recent-post">
                    <a href="#">
                      <img src="assets/img/blog/sm/3.jpg" alt="post" />
                    </a>
                    <div className="sigma_recent-post-body">
                      <a href="#">
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                      <h6>
                        <a href="#">
                          As we've all discovered by now, the world can change
                        </a>
                      </h6>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
      <Footer></Footer>
    </>
  );
}

export default Cart;
