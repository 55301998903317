import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Shop() {
  return (
    <>
      <Header> </Header>
      {/* partial:partia/__subheader.html */}
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>Shop</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a className="btn-link" href="#">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Shop
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* partial */}
      {/* Products Start */}
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {/* Product Count & Orderby Start */}
              <div className="sigma_shop-global">
                <p>
                  Showing <b>20</b> of <b>320</b> products
                </p>
                <form method="post">
                  <select className="form-control" name="orderby">
                    <option value="default">Default sorting</option>
                    <option value="latest">Latest release</option>
                    <option value="price-down">Price: High - Low</option>
                    <option value="price-up">Price: Low - High</option>
                    <option value="popularity">Popularity Sorting</option>
                  </select>
                </form>
              </div>
              {/* Product Count & Orderby End */}
              <div className="row masonry">
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/0.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Ramayan Book</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/1.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Bottles</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/2.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Lamp</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/3.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Gita Book</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                        <span>33$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/4.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Basic Chair</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/5.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Mahabharat Book</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/6.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Char Veda</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/7.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Yajur Veda</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/8.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Righ Veda</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/9.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Shyam Veda</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/10.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Work Bench</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
                {/* Product Start */}
                <div className="col-md-4 col-sm-6 masonry-item">
                  <div className="sigma_product">
                    <div className="sigma_product-thumb">
                      <a href="#">
                        <img src="assets/img/products/11.jpg" alt="product" />
                      </a>
                      <div className="sigma_product-controls">
                        <a href="#" data-toggle="tooltip" title="Wishlist">
                          <i className="far fa-heart" />
                        </a>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Add To Cart"
                        >
                          <i className="far fa-shopping-basket" />
                        </a>
                        <a href="#" data-toggle="tooltip" title="Quick View">
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#quickViewModal"
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sigma_product-body">
                      <h5 className="sigma_product-title">
                        <a href="#">Candle</a>
                      </h5>
                      <div className="sigma_product-price">
                        <span>29$</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product End */}
              </div>
              {/* Pagination Start */}
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="#">
                    2 <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
              </ul>
              {/* Pagination End */}
            </div>
            {/* Sidebar Start */}
            <div className="col-lg-4">
              <div className="sidebar">
                {/* Search Start */}
                <div className="sidebar-widget widget-search">
                  <h6 className="widget-title">Search</h6>
                  <form method="post">
                    <div className="sigma_search-adv-input">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Look for Products"
                        name="search"
                        defaultValue
                      />
                      <button type="submit" name="button">
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </form>
                </div>
                {/* Search End */}
                {/* Filter: Price Start */}
                <div className="sidebar-widget">
                  <h5 className="widget-title">Price</h5>
                  <input
                    type="text"
                    className="js-range-slider"
                    name="freshness_range"
                    defaultValue
                    data-type="double"
                    data-min={0}
                    data-max={500}
                    data-from={10}
                    data-to={100}
                    data-grid="true"
                    data-postfix=" $"
                  />
                </div>
                {/* Filter: Price End */}
                {/* Tags Start */}
                <div className="sidebar-widget widget-categories">
                  <h5 className="widget-title">Our Categories</h5>
                  <ul className="sidebar-widget-list">
                    <li>
                      <a href="#">
                        Avatars <span>32</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Festivals <span>22</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Mahabharat <span>17</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Mythology <span>05</span>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Tags End */}
                {/* Ad banner Start */}
                <div className="sidebar-widget widget-ad p-0 border-0">
                  <a href="#">
                    <img src="assets/img/blog/ad2.jpg" alt="ad" />
                    <div>
                      <span>Add a Custom Text</span>
                      Add Banner here
                    </div>
                  </a>
                </div>
                {/* Ad banner End */}
              </div>
            </div>
            {/* Sidebar End */}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Shop;
