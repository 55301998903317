import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Volunteer() {
  return (
    <>
      <Header> </Header>
      <>
        {/* partial:partia/__subheader.html */}
        <div
          className="sigma_subheader dark-overlay dark-overlay-2"
          style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
        >
          <div className="container">
            <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                <h1>Volunteers</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="btn-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Volunteers
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* partial */}
        {/* volunteers Start */}
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/1.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        {" "}
                        <a href="#" className="trigger-volunteers-socials">
                          {" "}
                          <i className="fal fa-plus" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-facebook-f" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-twitter" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-instagram" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p>Temple Memember</p>
                      <h5>
                        <a href="#">Yesh Chopra</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/8.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        {" "}
                        <a href="#" className="trigger-volunteers-socials">
                          {" "}
                          <i className="fal fa-plus" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-facebook-f" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-twitter" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-instagram" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p>Temple Memember</p>
                      <h5>
                        <a href="#">Rakesh K Pandey</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/2.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        {" "}
                        <a href="#" className="trigger-volunteers-socials">
                          {" "}
                          <i className="fal fa-plus" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-facebook-f" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-twitter" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-instagram" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p>Temple Memember</p>
                      <h5>
                        <a href="#">Murli Parsad</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/3.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        {" "}
                        <a href="#" className="trigger-volunteers-socials">
                          {" "}
                          <i className="fal fa-plus" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-facebook-f" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-twitter" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-instagram" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p>Temple Memember</p>
                      <h5>
                        <a href="#">Yesh Chopra</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/4.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        {" "}
                        <a href="#" className="trigger-volunteers-socials">
                          {" "}
                          <i className="fal fa-plus" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-facebook-f" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-twitter" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-instagram" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p>Temple Memember</p>
                      <h5>
                        <a href="#">Manisha Parsad</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/5.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        {" "}
                        <a href="#" className="trigger-volunteers-socials">
                          {" "}
                          <i className="fal fa-plus" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-facebook-f" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-twitter" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-instagram" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p>Temple Memember</p>
                      <h5>
                        <a href="#">Rakesh Singh</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/6.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        {" "}
                        <a href="#" className="trigger-volunteers-socials">
                          {" "}
                          <i className="fal fa-plus" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-facebook-f" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-twitter" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-instagram" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p>Temple Memember</p>
                      <h5>
                        <a href="#">Dharmendra Ram</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sigma_volunteers volunteers-4">
                  <div className="sigma_volunteers-thumb">
                    <img src="assets/img/volunteers/7.jpg" alt="volunteers" />
                    <ul className="sigma_sm">
                      <li>
                        {" "}
                        <a href="#" className="trigger-volunteers-socials">
                          {" "}
                          <i className="fal fa-plus" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-facebook-f" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-twitter" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#">
                          {" "}
                          <i className="fab fa-instagram" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="sigma_volunteers-body">
                    <div className="sigma_volunteers-info">
                      <p>Temple Memember</p>
                      <h5>
                        <a href="#">Jitendra Ram</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* volunteers End */}
        {/* Form Start */}
        <div
          className="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat"
          style={{ backgroundImage: "url(assets/img/bg1.jpg)" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-lg-30">
                <form method="post">
                  <div className="form-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-user" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="First Name"
                          name="fname"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-user" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Last Name"
                          name="lname"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-pencil" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Subject"
                          name="subject"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <i className="far fa-envelope" />
                        <input
                          type="text"
                          className="form-control transparent"
                          placeholder="Email Address"
                          name="email"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control transparent"
                          placeholder="Enter Message"
                          rows={4}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        className="sigma_btn-custom d-block w-100"
                        name="button"
                      >
                        {" "}
                        Get a Quote <i className="far fa-arrow-right" />{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/1.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/2.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/3.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/4.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/5.png" alt="client" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sigma_client">
                      <img src="assets/img/clients/6.png" alt="client" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Form End */}
        {/* Blog Start */}
        <div className="section section-padding">
          <div className="container">
            <div className="section-title text-center">
              <p className="subtitle">Blog</p>
              <h4 className="title">News Feed</h4>
            </div>
            <div className="row">
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/2.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Temple
                        </a>
                        ,
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        {" "}
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      {" "}
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>{" "}
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/1.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/1.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Temple
                        </a>
                        ,
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        {" "}
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      {" "}
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>{" "}
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/2.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
              {/* Article Start */}
              <div className="col-lg-4 col-md-6">
                <article className="sigma_post">
                  <div className="sigma_post-thumb">
                    <a href="#">
                      <img src="assets/img/blog/3.jpg" alt="post" />
                    </a>
                  </div>
                  <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                      <div className="me-3">
                        <i className="fas fa-om" />
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Temple
                        </a>
                        ,
                        <a
                          href="#"
                          className="sigma_post-category"
                        >
                          Love
                        </a>
                      </div>
                      <a href="#" className="sigma_post-date">
                        {" "}
                        <i className="far fa-calendar" /> May 20, 2024
                      </a>
                    </div>
                    <h5>
                      {" "}
                      <a href="#">
                        Education for all rural children are necessary.
                      </a>{" "}
                    </h5>
                    <div className="sigma_post-single-author">
                      <img src="assets/img/people/1.jpg" alt="author" />
                      <div className="sigma_post-single-author-content">
                        By <p>Yesh Chopra</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* Article End */}
            </div>
          </div>
        </div>
        {/* Blog End */}
      </>
      <Footer></Footer>
    </>
  );
}

export default Volunteer;
